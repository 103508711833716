.mainContainer {
  height: 152px;
  width: 475px;
  border-radius: 11px;
  background-color: #ffffff;
  box-shadow: 0 6px 50px 0 rgba(0, 0, 0, 0.13);
  display: flex;
  position: fixed;
  top: calc(50vh - 152px);
  left: calc(50vw - (475px / 2));
  z-index: 100000;
  .svgContainer {
    margin: 6px;
  }
  .textContainer {
    margin: 20px;
    .heading {
      height: 28px;
      width: 299px;
      color: #000000;
      font-family: Poppins;
      font-size: 20px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 30px;
    }
    .text1 {
      height: 69px;
      width: 299px;
      color: #000000;
      font-family: Poppins;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 25px;
    }
    .text2 {
      height: 69px;
      width: 299px;
      color: #000000;
      font-family: Poppins;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 25px;
    }
  }
}
.svgContainer {
  box-sizing: border-box;
  height: 135px;
  width: 121px;
  transform: rotate(210deg);
}
