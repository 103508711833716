.timeoutLeft {
    height: 35px;
    width: 495px;
    color: #000000;
    font-family: Poppins;
    font-size: 25px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 38px;
    margin: 37px 0px 12px 40px !important;
}
