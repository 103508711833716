$primary1: #7b5cd9;
$primary2: #7b5cd9;
$infoBackground: #EEEBF5;

$brandLive: #7b5cd9;
$brandDraft: #A68FF9;
$brandPending: #5d6fd3;
$brandClosed: #4ea9da;
$brandLost: #b485c5;

$campaignLive: #7b5cd9;
$campaignDraft: #3481ef;
$campaignPending: #5d6fd3;
$campaignClosed: #4ea9da;
$campaignLost: #b485c5;

$influencerLive: #7b5cd9;
$influencerInvite: #3481ef;
$influencerClosed: #4ea9da;
$influencerDeclined: #b485c5;
$influencerPending: #5d6fd3;

$dark1: #000000;
$dark2: #939393;
$dark3: #cccccc;

$authSidebarBackground: #d8d8d8;
$linkColor: #3481ef;

$danger: #db7474;

$fontColor: #000000;
$white: #ffffff;

$deselectedText: #7E7E7E;
