.cDialog {
    max-width: 700px !important;
    width: 700px !important;
    //max-height: 627px !important;
    border-radius: 20px !important;
    background-color: #ffffff !important;
    box-shadow: 0 6px 50px 0 rgba(0, 0, 0, 0.1) !important;
    .Contentdiv {
        .closeBtn {
            position: absolute;
            height: 21px;
            width: 21px;
            right: 35px;
            top: 30px;
            cursor: pointer;
        }
        .ConnectionImage {
            height: 121px;
            width: 121px;
            //background-color: red;
            margin: 100px 0px 0px 285px !important;
            img {
                width: 121px !important;
                height: 121px !important;
            }
        }
        .shopifystoreconnec {
            height: 35px;
            width: 495px;
            color: #000000;
            font-family: Poppins;
            font-size: 25px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 38px;
            text-align: center;
            margin: 37px 0px 12px 95px !important;
        }
        .store {
            height: 46px;
            width: 608px;
            color: #000000;
            font-family: Poppins;
            font-size: 16px;
            letter-spacing: 0;
            line-height: 25px;
            text-align: center;
            margin: 0px 41px 41px 41px !important;
        }
        .getstarted {
            height: 52px;
            width: 166px;
            color: #FFFFFF;
            font-family: Poppins;
            font-size: 19px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 29px;
            text-align: center;
            border-radius: 100px;
            background-color: #000000;
            border: none;
            outline: none;
            margin: 0px 0px 30px 490px !important;
            &:hover {
                cursor: pointer;
            }
            &:disabled, &[disabled] {
                background-color: #CCCCCC;
                color: #959595;
                &:hover {
                    cursor: not-allowed;
                }
            }
        }
    }
}