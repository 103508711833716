.shopName {
    box-sizing: border-box;
    width: 100%;
    padding: 30px;
}

.modalHeader {
    margin-top: 2rem;
    margin-left: 2rem;
    font-size: 20px;
}
.submit, .close {
    height: 52px;
    width: 166px;
    color: #FFFFFF;
    font-family: Poppins;
    font-size: 19px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 29px;
    text-align: center;
    border-radius: 100px;
    background-color: #000000;
    border: none;
    outline: none;
    margin: 0px 0px 30px 0px;
    &:hover {
        cursor: pointer;
    }
    &:disabled, &[disabled] {
        background-color: #CCCCCC;
        color: #959595;
        &:hover {
            cursor: not-allowed;
        }
    }
}

.close {
    background-color: transparent;
    color: black;
}

.buttonsDiv {
    display: flex;
    justify-content: flex-end;
    padding: 0 30px;
}

