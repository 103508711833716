.container{
    height: 1080px;
    width: 1920px;
  }
  .logo{
    height: 81px;
    width: 184px;
    cursor: pointer;
  }
  .logoDiv{
    position: absolute;
    top: 22px;
    left: 7px;
  }
  .blob{
    height: 734px;
    width: 743px;
    fill: #5D6FD3;
  }
  .blobDiv{
    position: absolute;
    top: 123px;
    left: 589px;
  }
  .blob404{
    height: 161px;
    width: 425px;
    fill: #2E3034;
    position: absolute;
    top: 239px;
    left: 159px;
  }
  .oopsText{
    height: 28px;
    width: 501px;
    color: #2E3034;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 30px;
    position: absolute;
    top: 429px;
    left: 120px;
  }
  .returnButton{
    box-sizing: border-box;
    height: 55px;
    width: 155px;
    border: 2px solid #7B5CD9;
    border-radius: 5px;
    background-color: #7B5CD9;
    color: #FFFFFF;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 25px;
    position: absolute;
    top: 519px;
    left: 294px;
  }