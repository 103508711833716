@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,700;1,400&display=swap');
@import './variable.module.scss';

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap');

html {
  height: 100%;
  scroll-behavior: smooth;
}
body {
  font-family: 'Poppins', 'poppins', sans-serif !important;
  color: $fontColor;
  margin: 0;
  height: 100%;
  background-color: white !important;
}

a {
  text-decoration: none;
  color: $linkColor;
  cursor: pointer;
}

h1 {
  font-size: 33px;
  font-family: Poppins, sans-serif;
  margin: 0;
  padding: 0;
}
h2 {
  font-size: 26px;
  font-family: Poppins, sans-serif;
  margin: 0;
  padding: 0;
}
h3 {
  font-size: 23px;
  font-family: Poppins, sans-serif;
  margin: 0;
  padding: 0;
}

h4 {
  font-size: 20px;
  font-family: Poppins, sans-serif;
  margin: 0;
  padding: 0;
}
h5 {
  font-size: 18px;
  font-family: Poppins, sans-serif;
  margin: 0;
  padding: 0;
}
p {
  margin: 0;
  padding: 0;
}

.chipBlkBtnSm {
  height: 31px;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 23px;
  text-align: center;
  color: $white;
  background: $dark1;
  border: none !important;
  padding: 0px 30px;
  border-radius: 15px;
  width: max-content !important;
  cursor: pointer;
  &:active,
  &:focus {
    outline: none;
  }
}

.hr {
  margin: 40px 0px;
  border: 0;
  height: 1px;
  background: #333;
  background-image: linear-gradient(to right, #ccc, #333, #ccc);
}

.defaultButton {
  color: $white !important;
  background-color: $dark1 !important;
}
.defaultOutlinedButton {
  color: $dark1 !important;
  border-color: $dark1 !important;
  background-color: $white !important;
  border: 2px solid #000000 !important;
}
.dangerButton {
  color: $white !important;
  background-color: $danger !important;
  width: 172px;
  height: 52px;
  box-shadow: none !important;
}
.textDangerButton {
  color: $danger !important;
  font-weight: 600 !important;
  font-size: 19px !important;
}

.MuiSelect-root {
  & label.Mui-focused {
    color: #000000;
    font-family: Poppins;
    font-size: 16px !important;
  }
  & .MuiInput-input {
    & placeholder {
      background: black;
      color: red !important;
      opacity: 1; /* Firefox */
    }
  }
  & .MuiOutlinedInput-root {
    & fieldset {
      border: 1px solid #939393;
      border-radius: 5px;
      font-family: Poppins;
      font-size: 16px;
    }
    &:hover fieldset {
      border-color: #939393;
    }
    &.Mui-focused fieldset {
      border: 1px solid #939393;
    }
  }
}

.MuiSelect-root:-ms-input-placeholder {
  color: red;
  opacity: 1;
}
.MuiSelect-root::-ms-input-placeholder {
  color: red;
  opacity: red;
}
.MuiSelect-root::placeholder {
  color: red;
  opacity: 1;
}

.countBadge {
  border: 1px solid $primary1;
  background: $primary1;
  border-radius: 100px;
  color: white;
  padding: 3px;
  padding-left: 10px;
  padding-right: 10px;
}

.placeholderColor {
  & > label {
    color: #7e7e7e !important;
    line-height: 20px !important;
  }
}

.colorWhite {
  color: $white !important;
}
.displayNone{
display: none !important;
}
